import React, { useEffect, useState } from "react";
import { adminControl } from "../dashboard/requests";
import { useAuth } from "../Auth/core/Auth";
import { useParams } from "react-router-dom";
import cn from 'classnames';
import { useNavigate } from "react-router-dom";



const AdminControl = () => {
    const { saveAuth, setCurrentUser , saveAuth2 } = useAuth();

    const [load, setLoad] = useState(true)

     const router = useNavigate(); 

    const { slug } = useParams();

    useEffect(() => {
        if (slug) {
            adminControl(slug).then(res => {
                saveAuth2(res);
                localStorage.setItem('token', res?.token);
                setCurrentUser('mentor')
                setLoad(false)
            }).catch(err => {
                window.open(process.env.REACT_APP_BASE_URL)
            })
        } else {
            window.open(process.env.REACT_APP_BASE_URL)
        }
    }, [slug])

    if (load) {
        return (
            <div className='pageLoading'>
                <span
                    className={cn(
                        'd-flex h-100vh w-100 flex-column align-items-center justify-content-center'
                    )}
                >
                    <span className={"loading"} />
                </span>
            </div>
        )
    }else {
        return  router('/profile')
    }

}



export default AdminControl; 